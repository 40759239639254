import {
  ITariffPricingOption,
  ITariffData,
  ITariffAdditionalServices,
  ITariffUserData,
  ITariffHistory,
} from 'entities/tariffs';
export class TariffHistoryModel implements ITariffHistory {
  readonly id: number;
  readonly tariff_name: string;
  readonly start: string;
  readonly end: string;
  constructor(params: ITariffHistory) {
    this.id = params.id;
    this.tariff_name = params.tariff_name;
    this.start = params.start;
    this.end = params.end;
  }
  static fromJson(raw: ITariffHistory): ITariffHistory {
    return {
      id: raw.id,
      tariff_name: raw.tariff_name,
      start: raw.start,
      end: raw.end,
    };
  }
}
export class TariffUserModel implements ITariffUserData {
  readonly id;
  readonly status;
  readonly modified_at;
  readonly expired_at;
  readonly user;
  readonly plan_option;
  readonly tariff_id;

  constructor(params: ITariffUserData) {
    this.id = params.id;
    this.status = params.status;
    this.modified_at = params.modified_at;
    this.expired_at = params.expired_at;
    this.user = params.user;
    this.plan_option = params.plan_option;
    this.tariff_id = params.tariff_id;
  }

  static fromJson(raw: ITariffUserData): ITariffUserData {
    return {
      id: raw.id,
      status: raw.status,
      modified_at: raw.modified_at,
      expired_at: raw.expired_at,
      user: raw.user,
      plan_option: raw.plan_option,
      tariff_id: raw.tariff_id,
    };
  }
}

export class TariffPricingOptionModel implements ITariffPricingOption {
  readonly id;
  readonly price;
  readonly billing_cycle;

  constructor(params: ITariffPricingOption) {
    this.id = params.id;
    this.price = params.price;
    this.billing_cycle = params.billing_cycle;
  }

  static fromJson(raw: ITariffPricingOption): ITariffPricingOption {
    return {
      id: raw.id,
      price: raw.price,
      billing_cycle: raw.billing_cycle,
    };
  }
}

export class TariffAdditionalServicesModel implements ITariffAdditionalServices {
  readonly id;
  readonly cost;
  readonly free_uses;
  readonly service;

  constructor(params: ITariffAdditionalServices) {
    this.id = params.id;
    this.cost = params.cost;
    this.free_uses = params.free_uses;
    this.service = params.service;
  }

  static fromJson(raw: ITariffAdditionalServices): ITariffAdditionalServices {
    return {
      id: raw.id,
      cost: raw.cost,
      free_uses: raw.free_uses,
      service: raw.service,
    };
  }
}

export class TariffModel implements ITariffData {
  readonly id;
  readonly pricing_options;
  readonly name;
  readonly plan_type;
  readonly active;
  readonly ordering;
  readonly access_to_objects;
  readonly access_to_source;
  readonly robot_assistant;
  readonly support_service;
  readonly is_trial;
  readonly is_free;
  readonly number_of_devices;
  readonly additional_services;
  readonly image;
  readonly price_text;
  readonly description;
  readonly static_advantages;

  constructor(params: ITariffData) {
    this.id = params.id;
    this.pricing_options = params.pricing_options;
    this.name = params.name;
    this.plan_type = params.plan_type;
    this.active = params.active;
    this.ordering = params.ordering;
    this.access_to_objects = params.access_to_objects;
    this.access_to_source = params.access_to_source;
    this.robot_assistant = params.robot_assistant;
    this.support_service = params.support_service;
    this.is_trial = params.is_trial;
    this.is_free = params.is_free;
    this.number_of_devices = params.number_of_devices;
    this.additional_services = params.additional_services;
    this.image = params.image;
    this.price_text = params.price_text;
    this.description = params.description;
    this.static_advantages = params.static_advantages || null;
  }

  static fromJson(raw: ITariffData): ITariffData {
    return {
      id: raw.id,
      pricing_options: raw.pricing_options.map((item) => TariffPricingOptionModel.fromJson(item)),
      name: raw.name,
      plan_type: raw.plan_type,
      active: raw.active,
      ordering: raw.ordering,
      access_to_objects: raw.access_to_objects,
      access_to_source: raw.access_to_source,
      robot_assistant: raw.robot_assistant,
      support_service: raw.support_service,
      is_trial: raw.is_trial,
      is_free: raw.is_free,
      number_of_devices: raw.number_of_devices,
      additional_services: raw.additional_services.map((item) => TariffAdditionalServicesModel.fromJson(item)),
      image: raw.image,
      price_text: raw.price_text,
      description: raw.description,
      static_advantages: raw.static_advantages ? raw.static_advantages : null,
    };
  }
}
