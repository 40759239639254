import { Spin } from 'antd';
import Breadcrumb, { BreadcrumbItemType, BreadcrumbSeparatorType } from 'antd/es/breadcrumb/Breadcrumb';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Navigate } from 'react-router-dom';

import { LayoutGridAside } from 'components/LayoutGridAside/LayoutGridAside';
import { PageContainer } from 'components/PageContainer';
import { PageListTitle } from 'components/PageListTitle';
import { ProfileAsideMenu } from 'components/profile/ProfileAsideMenu/ProfileAsideMenu';
import { routerUrls } from 'config/routes';
import { useRootStore } from 'stores/RootStore';
import { breadcrumbItemsRender } from 'utils/breadcrumbItemsRender';

import SubscriptionsBanner from './SubscriptionsBanner/SubscriptionsBanner';
import SubscriptionsHistory from './SubscriptionsHistory/SubscriptionsHistory';

import s from './SubscriptionsPage.module.scss';
import { TariffsStoreProvider } from 'stores/TariffsStore/context';
import { SubscriptionTariffs } from './SubscriptionTariffs/SubscriptionTariffs';
import { ProfileServicesStoreProvider } from 'stores/ProfileServicesStore/context';

const SubscriptionsPage = (): React.ReactElement => {
  const rootStore = useRootStore();

  const metaPage = React.useMemo(() => {
    const title = 'Пакеты услуг';
    const BREADCRUMB_ITEMS: Partial<BreadcrumbItemType & BreadcrumbSeparatorType>[] = [
      { title: 'Каталог объектов', href: routerUrls.lotsList.create() },
      { title: 'Личный кабинет', href: routerUrls.profileSettings.mask },
      { title: title },
    ];

    return {
      title,
      breadcrumbs: BREADCRUMB_ITEMS,
    };
  }, []);

  if (!rootStore.authStore.authorizingStage.isFinished) {
    return (
      <div className={s.profile__spinner}>
        <Spin size="large" />
      </div>
    );
  }

  if (!rootStore.userStore.authorized) {
    return <Navigate to={routerUrls.lotsList.create()} />;
  }
  return (
    <TariffsStoreProvider>
      <PageContainer withoutBottomPadding className={s.page}>
        <Breadcrumb
          className={s.profile__breadcrumbs}
          itemRender={breadcrumbItemsRender}
          items={metaPage.breadcrumbs}
        />
        <PageListTitle className={s.profile__title}>{metaPage.title}</PageListTitle>
        <LayoutGridAside aside={<ProfileAsideMenu />}>
          <div className={s.profile__content}>
            <SubscriptionsBanner />

            <SubscriptionTariffs />

            <SubscriptionsHistory />
          </div>
        </LayoutGridAside>
      </PageContainer>
    </TariffsStoreProvider>
  );
};

export default observer(SubscriptionsPage);
