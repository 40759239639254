import cn from 'classnames';
import * as React from 'react';

import { ListControlsBar } from 'components/ListControlsBar';
import useToggleState from 'hooks/useToggleState';

import s from './ListWithFilters.module.scss';
import { ListWithFiltersRobot } from 'components/lot/LotWithFiltersRobot';
import { PageContainer } from 'components/PageContainer';

type ListWithFiltersProps = {
  SideFilters: React.ComponentType<{ onFiltersClose: VoidFunction }>;
  sortComponent: React.ReactNode;
  listComponent: React.ReactNode;
  isListEmpty: boolean;
  isListTotalEmpty?: boolean;
  isFilterOptionsEmpty?: boolean;
  isFiltersCollapsed?: boolean;
  className?: string;
  isProfile?: boolean;
  unsetContainerList?: boolean;
};

const ListWithFilters = ({
  SideFilters,
  sortComponent,
  listComponent,
  isListEmpty,
  isListTotalEmpty = false,
  isFilterOptionsEmpty = false,
  isFiltersCollapsed = false,
  className,
  isProfile,
  unsetContainerList = false,
}: ListWithFiltersProps): React.ReactElement<ListWithFiltersProps> => {
  const { opened: isFiltersShown, open, toggle, close } = useToggleState();
  const filtersDivRef = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    if (filtersDivRef && filtersDivRef.current) {
      const isOpen = filtersDivRef.current?.getAttribute('aria-hidden') || 'true';
      if (isOpen === 'false') {
        filtersDivRef.current?.setAttribute('aria-hidden', 'true');
      } else {
        filtersDivRef.current?.setAttribute('aria-hidden', 'false');
      }
    }
  }, [isFiltersShown]);

  return (
    <div
      className={cn(
        s.container,
        isListTotalEmpty && s['container_no-filters'],
        isFiltersCollapsed && s['container_with-filters-collapsed'],
        className,
      )}
    >
      <PageContainer className={s.paddingVertivalNull}>
        <div className={s.row}>
          <ListWithFiltersRobot className={s.colLeft} />
          <div className={s.colRight} role="button" onClick={toggle}>
            Фильтры
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={isFiltersShown ? s.active : s.unactive}
            >
              <path
                d="M12.8123 3H11.6405C11.5608 3 11.4858 3.03906 11.4389 3.10313L6.99984 9.22188L2.56077 3.10313C2.5139 3.03906 2.4389 3 2.35921 3H1.18734C1.08577 3 1.0264 3.11563 1.08577 3.19844L6.59515 10.7937C6.79515 11.0687 7.20452 11.0687 7.40296 10.7937L12.9123 3.19844C12.9733 3.11563 12.9139 3 12.8123 3Z"
                fill="#5D5BED"
              />
            </svg>
          </div>
        </div>
      </PageContainer>

      <div className={s.filters} aria-hidden={isFiltersShown} ref={filtersDivRef}>
        <div>
          <PageContainer className={s.paddingVertivalNull}>
            <SideFilters onFiltersClose={close} />
          </PageContainer>
        </div>
      </div>

      <div className={s.container__list}>
        <PageContainer className={s.paddingVertivalNull}>
          <ListControlsBar
            isProfile={isProfile}
            isListEmpty={isListEmpty}
            onFiltersClick={open}
            sortComponent={sortComponent}
            className={cn(isFiltersCollapsed && s['container__list-controls_collapsed'])}
          />
        </PageContainer>
        {unsetContainerList ? (
          listComponent
        ) : (
          <PageContainer className={s.paddingVertivalNull}>{listComponent}</PageContainer>
        )}
      </div>
    </div>
  );
};

export default ListWithFilters;
