export enum TariffPlanType {
  personal = 'personal',
  corporate = 'corporate',
}

export enum TariffPricingBillingCycle {
  day = 'day',
  month = 'month',
  year = 'year',
  trial = 'trial',
  free = 'free',
}

export interface ITariffSubscription {
  id: number;
  status: string;
  modified_at: string;
  expired_at: string;
  user: number;
  plan_option: number;
}

export const DICTIONARY_BILLING_CYCLE = {
  [TariffPricingBillingCycle.day]: 'день',
  [TariffPricingBillingCycle.month]: 'месяц',
  [TariffPricingBillingCycle.year]: 'год',
  [TariffPricingBillingCycle.trial]: 'пробный',
  [TariffPricingBillingCycle.free]: 'бесплатно',
};

export interface ITariffAdditionalServices {
  id: number;
  cost: string;
  free_uses: number;
  service: number;
}

export interface ITariffHistory {
  id: number;
  tariff_name: string;
  start: string;
  end: string;
}

export interface ITariffData {
  id: number;
  pricing_options: ITariffPricingOption[];
  name: string;
  plan_type: TariffPlanType;
  active: boolean;
  ordering: number;
  access_to_objects: boolean;
  access_to_source: boolean;
  robot_assistant: boolean;
  support_service: boolean;
  is_trial: boolean;
  is_free: boolean;
  number_of_devices: number;
  additional_services: ITariffAdditionalServices[];
  image: string | null;
  price_text: string | null;
  description: string | null;
  static_advantages?: null | string[];
}

export interface ITariffPricingOption {
  id: number;
  price: string;
  billing_cycle: TariffPricingBillingCycle;
}

export enum TariffUserStatus {
  incomplete = 'incomplete',
  active = 'active',
  canceled = 'canceled',
  unpaid = 'unpaid',
  paused = 'paused',
  expired = 'expired',
}

export interface ITariffUserData {
  id: number;
  status: TariffUserStatus;
  modified_at: string;
  expired_at: string;
  user: number;
  plan_option: number;
  tariff_id: number;
}
